import PropTypes from "prop-types"
import styled from "styled-components"
import {
  Layout,
  Hero,
  About,
  Skills,
  Featured,
  Projects,
  Contact,
} from "@components"

const StyledMainContainer = styled.main`
  counter-reset: section;
`

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <StyledMainContainer className="fillHeight">
      <Hero />
      <About />
      <Skills />
      <Featured />
      <Projects />
      <Contact />
    </StyledMainContainer>
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
